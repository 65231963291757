<template>
  <div>
    <small class="pl-2 has-text-weight-medium">Меню</small>
    <ul class="mt-2">
      <li
        v-for="item in menuItems"
        :key="item.title"
        @click="handleMenuItemClick(item)"
      >
        <div :class="['side-bar__item', activeMenuItem === item.title && 'side-bar__item--active']">
          <div class="is-flex is-align-items-center">
            <div class="side-bar__item__icon-container">
              <fa-icon :icon="['fas', item.icon]" />
            </div>
            <div class="ml-1 has-text-weight-medium is-flex-grow-1">
              {{ item.title }}
            </div>
          </div>
          <div v-if="item.content">
            <template v-if="activeMenuItem !== item.title">
              <fa-icon :icon="['fas','chevron-down']" />
            </template>
            <template v-else>
              <fa-icon :icon="['fas','angle-up']" />
            </template>
          </div>
        </div>
        <ul
          class="ml-5 my-2"
          v-if="activeMenuItem === item.title"
        >
          <li class="is-flex">
            <div class="mr-3">
              <fa-icon :icon="['fas','list']" />
            </div>
            <router-link
              class="item-link"
              tag="a"
              :to="item.content.list"
            >Список
            </router-link>
          </li>

          <li class="is-flex mt-2">
            <div class="mr-3">
              <fa-icon :icon="['fas','pencil']" />
            </div>
            <router-link
              class="item-link"
              tag="a"
              :to="item.content.registration"
            >Зарегистрировать
            </router-link>
          </li>
        </ul>
      </li>
      <!-- <li class="pb-3"  @click="openCatalog =! openCatalog">
        <div class="side-bar__item">
          <div>
            icon
          </div>
          <div class="route-item">
            Каталог
          </div>
        </div>

        <ul v-if="openCatalog">
          <li class="is-flex">
            <div>logo</div>
            <router-link to="/catalog-list">Список</router-link>
          </li>
          <li class="is-flex">
            <div>logo</div>
            <router-link to="/catalog-registration">Зарегистрировать</router-link>
          </li>
        </ul>
      </li> -->
      <!-- <li class="pb-3 side-bar__item">
        <div>
          icon
        </div>
        <div class="route-item">
          <router-link to="/editor">Редактор кода</router-link>
        </div>
      </li> -->
    </ul>
  </div>
</template>

<script>

export default {
  data() {
    return {
      activeMenuItem: undefined,
      menuItems: [
        {
          title: "Главная",
          icon: "cube",
          to: "/",
        },
        {
          title: "Компания",
          icon: "building",
          content: {
            list: "/company-list",
            registration: "/company-registration",
          },
        },
        {
          title: "Филиалы",
          icon: "code-branch",
          content: {
            list: "/filials-list",
            registration: "/filials-registration",
          },
        },
        {
          title: "Завод/Фабрика/Цех",
          icon: "wrench",
          content: {
            list: "/factory-list",
            registration: "/factory-registration",
          },
        },
        {
          title: "СМS",
          icon: "toggle-off",
          content: {
            list: "/cms-list",
            registration: "/cms-registration",
          },
        },
        {
          title: "Пользователи",
          icon: "user-group",
          content: {
            list: "/users-list",
            registration: "/users-registration",
          },
        },
        {
          title: "Каталог",
          icon: "table",
          to: '/catalogs'
        },
        {
          title: "Типы подшипников",
          icon: "ring",
          to: '/bearings'
        },
      ],
    };
  },
  methods: {
    handleMenuItemClick(item) {
      if (item.to && this.$route.path !== item.to) this.$router.push(item.to);
      else if (item.content) this.activeMenuItem = this.activeMenuItem !== item.title ? item.title : undefined;
    },
  },
};
</script>

<style>
.side-bar__item__icon-container {
  width: 20px;
  height: 20px;
  display: grid;
  place-items: center;
}

.side-bar__item {
  padding: 11px 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.side-bar__item--active {
  background-color: rgb(203, 206, 207);
}

.side-bar__item:hover {
  transition: .5s;
  background-color: rgb(203, 206, 207);
}


.item-link {
  flex: 1;
  color: black;
  font-size: 15px;
}

.item-link:hover {
  font-size: 16px;
  transition: .45s;
}
</style>
