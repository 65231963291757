import Vue from "vue"
import VueRouter from "vue-router"
import axios from "axios"

Vue.use(VueRouter)

const routes = [
  {
    path: "/home",
    name: "Home",
    component: () => import("../views/Home.vue"),
  },
  {
    path: "/login",
    name: "Login",
    meta: { layout: "empty", title: "Авторизация" },
    component: () => import("../components/Login.vue"),
  },
  {
    path: "/logout",
    name: "Logout",
    meta: { layout: "empty", title: "Выход" },
    component: () => import("../components/Login.vue"),
  },
  {
    path: "/",
    name: "Main",
    meta: { layout: "main", title: "Административная панель" },
    component: () => import("../components/Main.vue"),
  },
  {
    path: "/company-list",
    name: "Company-list",
    meta: { layout: "main", title: "Список компании" },
    component: () => import("../components/Company/Company-list.vue"),
  },
  {
    path: "/company-editor/:id",
    name: "Company-editor",
    meta: { layout: "main", title: "Редактирование компании" },
    component: () => import("../components/Company/Sub-component/Company-editor.vue"),
  },
  {
    path: "/company-registration",
    name: "Company-registration",
    meta: { layout: "main", title: "Регистрация компании" },
    component: () => import("../components/Company/Company-registration.vue"),
  },
  {
    path: "/filials-list",
    name: "Filials-list",
    meta: { layout: "main", title: "Список филиалов" },
    component: () => import("../components/Filials/Filials-list.vue"),
  },
  {
    path: "/filials-registration",
    name: "Filials-registration",
    meta: { layout: "main", title: "Регистрация филиала" },
    component: () => import("../components/Filials/Filials-registration.vue"),
  },
  {
    path: "/filial-editor/:id",
    name: "Filial-editor",
    meta: { layout: "main", title: "Редактирование филиала" },
    component: () => import("../components/Filials/Sub-component/Filial-editor.vue"),
  },
  {
    path: "/factory-list",
    name: "Factory-list",
    meta: { layout: "main", title: "Список заводов" },
    component: () => import("../components/Factory/Factory-list.vue"),
  },
  {
    path: "/factory-registration",
    name: "Factory-registration",
    meta: { layout: "main", title: "Регистрация заводов" },
    component: () => import("../components/Factory/Factory-registration.vue"),
  },
  {
    path: "/factory-editor/:id",
    name: "Factory-editor",
    meta: { layout: "main", title: "Редактирование завода" },
    component: () => import("../components/Factory/Sub-component/Factory-editor.vue"),
  },
  {
    path: "/cms-list",
    name: "CMS-list",
    meta: { layout: "main", title: "Список CMS" },
    component: () => import("../components/CMS/CMS-list.vue"),
  },
  {
    path: "/cms/:id/spectra",
    name: "SpectraList",
    meta: { layout: "main", title: "Спектры CMS" },
    component: () => import("../views/cms/spectra/index.vue"),
  },
  {
    path: "/cms-registration",
    name: "CMS-registration",
    meta: { layout: "main", title: "Регистрация CMS" },
    component: () => import("../components/CMS/CMS-registration.vue"),
  },
  {
    path: "/cms-editor/:id",
    name: "Cms-editor",
    props: true,
    meta: { layout: "main", title: "Редактирование CMS" },
    component: () => import("../components/CMS/Sub-component/Cms-editor.vue"),
  },
  {
    path: "/cms-editor/:id/reports",
    name: "Cms-reports",
    meta: { layout: "main", title: "Отчёты CMS" },
    component: () => import("../components/CMS/Sub-component/Сms-reports.vue"),
  },
  {
    path: "/users-list",
    name: "Users-list",
    meta: { layout: "main", title: "Список пользователей" },
    component: () => import("../components/Users/Users-list.vue"),
  },
  {
    path: "/users-registration",
    name: "Users-registration",
    meta: { layout: "main", title: "Регистрация пользователей" },
    component: () => import("../components/Users/Users-registration.vue"),
  },
  {
    path: "/users-editor/:id",
    name: "Users-editor",
    meta: { layout: "main", title: "Редактор пользователей" },
    component: () => import("../components/Users/Sub-component/Users-editor.vue"),
  },
  {
    path: "/catalogs",
    name: "CatalogIndex",
    meta: { layout: "main", title: "Список каталогов" },
    component: () => import("../views/catalog/index.vue"),
  },
  {
    path: "/catalogs/:catalog_id",
    name: "CatalogShow",
    meta: { layout: "main", title: "Каталог" },
    props: true,
    component: () => import("../views/catalog/show.vue"),
  },
  {
    path: "/catalog-registration",
    name: "Catalog-registration",
    meta: { layout: "main", title: "Регистрация каталога" },
    component: () => import("../components/Catalog/Catalog-registration.vue"),
  },
  {
    path: "/bearings",
    name: "BearingList",
    meta: { layout: "main", title: "Типы подшипников" },
    component: () => import("../views/BearingTypeList.vue"),
  },
  {
    path: "/editor",
    name: "Editor",
    meta: { layout: "main", title: "Редактирование каталога" },
    component: () => import("../components/Editor/Editor.vue"),
  },


]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
})


router.beforeEach((to, from, next) => {
  document.title = to.meta.title

  let token = localStorage.getItem("token")
  // проверяем - если токена нет, и станица не логин или восстановление , то переходим на логин
  if (to.name !== "Login" && !token) next({ name: "Login" })
  else if (to.name === "Login" && token) {
    next({ name: "Main" })
  } else if (to.name === "Logout" && token) {
    localStorage.removeItem("token")
    next({ name: "Login" })
    axios.defaults.headers.common["Authorization"] = null
    next({ name: "Login" })
  } else next()
})

export default router
